import React, { useState, useEffect, useRef } from 'react';
import { Search, ArrowRight } from 'lucide-react';
import './App.css';
import { FaMoon, FaEnvelope, FaYoutube, FaShoppingCart, FaGlobe, FaDice } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip'; // You'll need to install this package

const useTypingEffect = (phrases, typingSpeed = 100, erasingSpeed = 50, delay = 2000) => {
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
    const handleTyping = () => {
      const currentPhrase = phrases[loopNum % phrases.length];
      const updatedText = isDeleting
        ? currentPhrase.substring(0, typingIndex - 1)
        : currentPhrase.substring(0, typingIndex + 1);

      setDisplayText(updatedText);
      setTypingIndex(isDeleting ? typingIndex - 1 : typingIndex + 1);

      if (!isDeleting && updatedText === currentPhrase) {
        setTimeout(() => setIsDeleting(true), delay);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setTypingIndex(0);
      }
    };

    const typingTimeout = setTimeout(handleTyping, isDeleting ? erasingSpeed : typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [displayText, isDeleting, typingIndex, loopNum, phrases, typingSpeed, erasingSpeed, delay]);

  return displayText;
};


const SkeletonCard = () => (
  <div className="skeleton-card">
    <div className="skeleton-thumbnail"></div>
    <div className="skeleton-content">
      <div className="skeleton-line skeleton-title"></div>
      <div className="skeleton-line skeleton-description"></div>
    </div>
  </div>
);

const LazyImage = ({ src, alt }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return loaded ? <img src={src} alt={alt} className="recommendation-thumbnail" /> : <SkeletonCard />;
};


const RecommendationCard = ({ recommendation }) => {
  const [currentVersion, setCurrentVersion] = useState(0);
  const versions = [recommendation, ...(recommendation.duplicates || [])];

  const handleVersionChange = () => {
    setCurrentVersion((prev) => (prev + 1) % versions.length);
  };

  const currentGame = versions[currentVersion];

  return (
    <div className="recommendation-card">
      <LazyImage src={currentGame.thumbnail} alt={currentGame.name} />
      <div className="recommendation-content">
        <h3 className="recommendation-title">{currentGame.name}</h3>
        <p className="recommendation-description">{recommendation.description}</p>
        <div className="game-details">
          <p><strong>Year:</strong> {currentGame.year}</p>
          <p><strong>Players:</strong> {currentGame.minPlayers} - {currentGame.maxPlayers}</p>
          <p><strong>Playing Time:</strong> {currentGame.playingTime} minutes</p>
        </div>
        <div className="icon-container">
          {currentGame.videoLink && currentGame.videoLink !== 'No video available' && (
            <a href={currentGame.videoLink} target="_blank" rel="noopener noreferrer" className="youtube-icon" aria-label="Watch video on YouTube">
              <FaYoutube size={24} />
            </a>
          )}
          {currentGame.affiliateLink && (
            <a 
              href={currentGame.affiliateLink} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="shopping-cart-icon"
              data-tooltip-id="affiliate-tooltip"
              data-tooltip-content="We may earn a commission if you make a purchase."
              aria-label="Buy this game"
            >
              <FaShoppingCart size={24} />
            </a>
          )}
          {currentGame.bggLink && (
            <a href={currentGame.bggLink} target="_blank" rel="noopener noreferrer" className="web-icon" aria-label="View on BoardGameGeek">
              <FaGlobe size={24} />
            </a>
          )}
        </div>
        {versions.length > 1 && (
          <button onClick={handleVersionChange} className="version-switch-button" aria-label={`Switch to version ${currentVersion + 2}`}>
            Duplicate Name ({currentVersion + 1}/{versions.length})
          </button>
        )}
      </div>
    </div>
  );
};

const PromotedContentCard = () => (
  <div className="promoted-content-card relative">
    <div className="absolute top-2 right-2 bg-black text-white text-xs px-2 py-1 rounded">
      AD
    </div>
    <div className="flex justify-center items-center h-full">
      <p className="text-gray-700">Advertise Your Game Here!</p>
    </div>
  </div>
);

const App = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [game, setGame] = useState('');
  const [autocompleteSuggestion, setAutocompleteSuggestion] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const inputRef = useRef(null);
  const [yearFilter, setYearFilter] = useState('');
  const [playerCountFilter, setPlayerCountFilter] = useState('');
  const [playTimeFilter, setPlayTimeFilter] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  const MAX_RECOMMENDATIONS = 6;
  const AD_INSERT_POSITION = 2;

  const commonPhrase = 'Discover your next ';
  const phrases = [
    'strategy boardgame',
    'game night hit',
    'party game',
    'game for two',
    'cooperative challenge',
    'deck-building game',
    'family boardgame',
  ];


  const typingText = useTypingEffect(phrases);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      document.documentElement.classList.toggle('dark', newMode);
      return newMode;
    });
  };

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  const fetchSuggestions = async (query) => {
    if (!query) {
      setAutocompleteSuggestion('');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/autocomplete?q=${query}`);
      const data = await response.json();

      if (data.length > 0) {
        const suggestion = data[0].name;
        if (suggestion.toLowerCase().startsWith(query.toLowerCase())) {
          setAutocompleteSuggestion(suggestion);
        } else {
          setAutocompleteSuggestion('');
        }
      } else {
        setAutocompleteSuggestion('');
      }
    } catch (err) {
      console.error('Error fetching autocomplete suggestions:', err.message);
    }
  };

  const handleInputChange = async (e) => {
    const userInput = e.target.value;
    setGame(userInput);
    await fetchSuggestions(userInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRecommendations([]);
    setHasSearched(true);

    try {
      const response = await fetch(`${API_URL}/recommend`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ game, year: yearFilter, playerCount: playerCountFilter, playTime: playTimeFilter }),
      });


      if (response.status === 429) {
        throw new Error('Limit of 15 searches within a 30-minute period exceeded. Please try again in 30 minutes.');
      } else if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.recommendations && Array.isArray(data.recommendations)) {
        setRecommendations(data.recommendations.slice(0, MAX_RECOMMENDATIONS));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error details:', err);
      setError(`${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasSearched) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hasSearched]);

  useEffect(() => {
    if (recommendations.length > 0) {
      const imagePromises = recommendations.map((rec) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = rec.thumbnail;
          img.onload = resolve;
          img.onerror = resolve;
        });
      });

      Promise.all(imagePromises)
        .then(() => {})
        .catch((err) => {
          console.error('Error loading images', err);
        });
    }
  }, [recommendations]);

  const getAutocompleteParts = () => {
    if (!autocompleteSuggestion || !game) return { userInput: '', suggestion: '' };

    const userInputLength = game.length;
    const suggestionLength = autocompleteSuggestion.length;

    if (userInputLength > suggestionLength) {
      return { userInput: game, suggestion: '' };
    }

    if (autocompleteSuggestion.slice(0, userInputLength).toLowerCase() === game.toLowerCase()) {
      const suggestionPart = autocompleteSuggestion.slice(userInputLength);
      return {
        userInput: game,
        suggestion: suggestionPart,
      };
    }

    return { userInput: game, suggestion: '' };
  };


  const { userInput, suggestion } = getAutocompleteParts();

  let displayItems = [...recommendations];

  // Ensure the ad is always inserted if there are any recommendations
  if (displayItems.length > 0) {
    const adPosition = Math.min(AD_INSERT_POSITION, displayItems.length);
    displayItems.splice(adPosition, 0, { type: 'ad' });
  }

  // Limit the display items to the maximum recommendations plus one for the ad
  if (displayItems.length > MAX_RECOMMENDATIONS + 1) {
    displayItems = displayItems.slice(0, MAX_RECOMMENDATIONS + 1);
  }


  return (
    <div className={`min-h-screen flex flex-col ${darkMode ? 'dark bg-gray-900' : 'bg-board-game-pattern'} transition-all duration-500 ease-in-out`}>
      <div className="flex-grow flex flex-col items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
        <div className={`w-full max-w-2xl space-y-4 transition-all duration-500 ease-in-out ${hasSearched ? '' : 'transform -translate-y-1/4'}`}>
          <h1 className={`font-display text-5xl font-bold text-center text-purple-800 mb-2 transition-all duration-500 ${hasSearched ? 'text-4xl mb-4' : ''}`}>
            <a href="https://findboard.games" className="flex flex-col items-center justify-center">
              <FaDice className="mb-2" size={48} />
              Find Board Games
            </a>
          </h1>
          {!hasSearched && (
            <>
              <div className="typing-effect-container">
                <p className="font-body text-2xl text-center text-purple-600 transition-all duration-500">
                  {commonPhrase}<span className="min-h-[1.5em] inline-block">{typingText}</span>
                </p>
              </div>
              <div className="static-description">
                <p className="font-body text-2xl text-center text-purple-600 transition-all duration-500">
                  Discover your next favorite board game
                </p>
              </div>
            </>
          )}

          <form onSubmit={handleSubmit} className="relative search-form" aria-label="Search for board games">
            {!hasSearched && (
              <>
                <img src={`https://findboard.games/assets/meeple-1.png`} alt="Meeple" className="meeple meeple-left-1" />
                <img src={`https://findboard.games/assets/meeple-2.png`} alt="Meeple" className="meeple meeple-left-2" />
                <img src={`https://findboard.games/assets/meeple-3.png`} alt="Meeple" className="meeple meeple-left-3" />
              </>
            )}
            <div className="flex items-center space-x-3">
              <div className="relative flex-grow flex items-center">
                <Search className="absolute left-4 text-gray-400" aria-label="Search icon" />
                <input
                  ref={inputRef}
                  type="text"
                  value={game}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab' && autocompleteSuggestion) {
                      e.preventDefault();
                      setGame(autocompleteSuggestion);
                      setAutocompleteSuggestion('');
                    }
                  }}
                  placeholder="Enter a board game you like"
                  required
                  className="w-full pl-12 pr-6 py-4 text-xl text-gray-900 bg-white rounded-full shadow-lg focus:outline-none transition-all duration-300 search-pulse font-body search-form search-input"
                  aria-label="Board game search input"
                />
                <div className="autocomplete-suggestion">
                  <span className="suggestion-input">{userInput}</span>
                  <span className="suggestion-autocomplete">{suggestion}</span>
                </div>
                {autocompleteSuggestion && (
                  <div className="tab-icon">
                    <img src="http://localhost:3000/assets/keyboard-tab-svgrepo-com.svg" alt="Tab Icon" />
                  </div>
                )}
              </div>
              <button
                type="submit"
                disabled={loading}
                className="bg-purple-600 text-white rounded-full p-4 hover:bg-purple-700 focus:outline-none focus:ring-4 focus:ring-purple-600 focus:ring-opacity-50 transition-all duration-300"
                aria-label="Search button"
              >
                {loading ? (
                  <div className="w-6 h-6 border-t-2 border-white border-solid rounded-full animate-spin" aria-label="Loading spinner"></div>
                ) : (
                  <ArrowRight className="w-6 h-6" aria-label="Arrow icon" />
                )}
              </button>
            </div>
          </form>

          {hasSearched && (
            <div className="mt-4 flex space-x-4">
              <select
                value={yearFilter}
                onChange={(e) => setYearFilter(e.target.value)}
                className="dropdown"
              >
                <option value="">Select Year</option>
                <option value="2018+">2018+</option>
                <option value="2019+">2019+</option>
                <option value="2020+">2020+</option>
                <option value="2021+">2021+</option>
                <option value="2022+">2022+</option>
                <option value="2023+">2023+</option>
              </select>
              <select
                value={playerCountFilter}
                onChange={(e) => setPlayerCountFilter(e.target.value)}
                className="dropdown"
              >
                <option value="">Select Player Count</option>
                <option value="1+">1+</option>
                <option value="2+">2+</option>
                <option value="3+">3+</option>
                <option value="4+">4+</option>
                <option value="5+">5+</option>
                <option value="6+">6+</option>
                <option value="7+">7+</option>
                <option value="8+">8+</option>
                <option value="9+">9+</option>
                <option value="10+">10+</option>
              </select>
              <select
                value={playTimeFilter}
                onChange={(e) => setPlayTimeFilter(e.target.value)}
                className="dropdown"
              >
                <option value="">Select Play Time</option>
                <option value="30-">Less than 30 minutes</option>
                <option value="30-60">30 to 60 minutes</option>
                <option value="60+">More than 60 minutes</option>
              </select>
            </div>
          )}

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg font-body"
              role="alert"
              aria-live="assertive"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}

          {hasSearched && !loading && !error && (
            <div className="space-y-4 animate-fade-in-up">
              {displayItems.length > 0 ? (
                <div className="recommendation-container">
                  {displayItems.map((item, index) => {
                    if (item && item.type === 'ad') {
                      return <PromotedContentCard key={index} />;
                    } else if (item) {
                      return <RecommendationCard key={index} recommendation={item} />;
                    } else {
                      return <div key={index} className="placeholder-card"></div>;
                    }
                  })}
                </div>
              ) : (
                <p className="font-body text-xl text-center text-gray-700">No recommendations found.</p>
              )}
            </div>
          )}

          {loading && !error && (
            <div className="space-y-4 animate-fade-in-up">
              {[...Array(MAX_RECOMMENDATIONS + 1)].map((_, index) => <SkeletonCard key={index} />)}
            </div>
          )}
        </div>
      </div>

      {/* Add the disclaimer above the footer, only when hasSearched is true */}
      {hasSearched && (
        <div className="w-full text-center py-4">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Recommendations are generated by AI and may not always be accurate.
          </p>
        </div>
      )}

      {/* Footer with social icons and dark mode toggle */}
      <footer className="py-4 mt-auto w-full">
        <div className="flex justify-start items-center space-x-6 pl-4 sm:pl-8 md:pl-12 lg:pl-16">
          {/* Mail icon link */}
          <a href="mailto:findboardgames@gmail.com" className="text-gray-500 hover:text-blue-500 flex items-center justify-center w-6 h-6" aria-label="Email us">
            <FaEnvelope size={24} />
          </a>

          {/* Dark mode toggle button */}
          <button onClick={toggleDarkMode} className="text-gray-500 hover:text-yellow-500 focus:outline-none w-6 h-6 flex items-center justify-center" aria-label="Toggle dark mode">
            <FaMoon size={24} />
          </button>
        </div>
      </footer>

      {/* Add this near the end of the return statement */}
      <Tooltip id="affiliate-tooltip" />
    </div>
  );
};

export default App;